import React from "react";
import imgs from "../../assets/images/contact-img.jpg";
import emailjs from '@emailjs/browser';

function Contact() {
  const formSubmit = (event) => {
    event.preventDefault(); // Prevent form from submitting

    // Get form data
    var formData = {
      name: document.getElementById('name').value,
      email: document.getElementById('email').value,
      phone: document.getElementById('phone').value,
      message: document.getElementById('message').value
    };

    console.log(formData)

    // Send email using EmailJS
    emailjs.send("service_bz1x12y", "template_oogtfv7", formData, 'KWQY7kbdPDtLW_Itx')
    .then((result) => {
      alert('Message sent successfully!');
      console.log(result.text);
  }, (error) => {
    console.error('Error sending email:', error);
    alert('Failed to send message. Please try again later.');
  });
  };

  return (
    <>
    <section id="contact" className=" bg-white relative flex flex-wrap lg:h-screen lg:items-center">
      <div className="w-full px-4 py-8 sm:px-6 sm:py-16 lg:w-1/2 lg:px-8 lg:py-24">
        <div className="mx-auto max-w-lg text-center">
          <h1 className="text-2xl font-bold sm:text-3xl">Get started today!</h1>

          <p className="mt-4 text-gray-500">
          Thank you again for considering <b>SWASTIMANSH</b> as your trusted partner in health. We look forward to hearing from you soon!
          </p>
        </div>



        <form id="contact-form" className="mx-auto mt-4 mb-0 max-w-sm space-y-4" onSubmit={formSubmit}>
          <div className="gap-2 py-2 grid grid-cols-2 md:grid-cols-2 mt-6 ">
              <input
                className=" h-14 rounded-2xl  border-[1px] pl-4   "
                type="text"
                placeholder="Name"
                id="name" name="name" 
              />
              <input
                className=" h-14  rounded-2xl  border-[1px] pl-4"
                type="phone"
                placeholder="Phone"
                id="phone" name="phone" 
              />
            </div>
            <div className="gap-2 py-2 grid grid-cols-1 md:grid-cols-1 mt-6">
              <input
                className=" h-14  rounded-2xl   border-[1px] pl-4 "
                type="email"
                placeholder="Email"
                id="email" name="email"
              />
              
            </div>
            <textarea
              className=" rounded-2xl border-[1px]  h-36 pl-4  w-full  pt-2"
              rows={40}
              cols={35}
              placeholder="Message"
              id="message" name="message" 
            ></textarea>

          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="inline-block rounded-lg bg-blue-500 px-5 py-3 text-sm font-medium text-white"
            >
              Connect
            </button>
          </div>
        </form>
      </div>

      <div className="relative hidden lg:block mt-12 w-full sm:h-96 lg:h-full lg:w-1/2 justify-center ">
        <img
          alt="Welcome"
          src={imgs}
          style={{ maxWidth: '40rem' }}
          className="absolute inset-0 object-cover"
        />
      </div>
    </section>

    </>
  );
}

export default Contact;