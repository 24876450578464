import React from 'react'
import Home from './assets/home'
import './index.css'
import './style.css'


function App() {
  return (
    <>
     <Home/>
    </>
  )
}

export default App;
